import React, { ReactElement } from 'react';
import { Typography } from '@material-ui/core';

export default function NotFound404(): ReactElement {
  return (
    <div className="flex items-center justify-center w-full h-screen">
      <Typography variant="h5">Page not found...</Typography>
    </div>
  );
}
